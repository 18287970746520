import { Button, Grid, Card } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { signInWithGoogle } from "../../redux/ducks/auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(3),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  })
);
const HomePage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.root}
      justify="center"
      alignContent="center"
    >
      <Grid item xs={12} sm={6}>
        <Grid item style={{ marginTop: 20 }}>
          <Card elevation={1} style={{ padding: 60 }}>
            <Button
              color="primary"
              variant="contained"
              style={{ alignSelf: "flex-end", marginTop: 10 }}
              onClick={() => {
                dispatch(signInWithGoogle.requestWithPopUp());
              }}
            >
              Sign-In with Google
            </Button>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomePage;
