import * as firebase from "firebase/app";
import "firebase/firestore";
import { User } from "../types/User";

class UsersRepository {
  static async getUserById(userId: string): Promise<User | null> {
    const queryResult = await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get();
    if (queryResult.exists) {
      return queryResult.data() as User;
    }
    return null;
  }

  static async createUser(userId: string, user: User): Promise<void> {
    return firebase.firestore().collection("users").doc(userId).set(user);
  }
}

export default UsersRepository;
