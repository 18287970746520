import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(
  version: string,
  createdAt: string,
  shouldForceUpdate: boolean,
  url: string,
  updateText: string
) {
  return { version, createdAt, shouldForceUpdate, url, updateText };
}
function trunc(val: string, n: number) {
  return val.substr(0, n - 1) + (val.length > n ? "&hellip;" : "");
}
const rows = [
  createData(
    "0.2.4",
    "Feb 5, 2021",
    false,
    "/Continuous_master_Eden-0.2.4-284820b-Release_020421_105509_DFU_Eden_Bootloader-0.2.5-1d70012-Release.zip",
    "new bootloader value"
  ),
  createData(
    "0.2.2",
    "Feb 3, 2021",
    true,
    "/Continuous_master_Eden-0.2.4-284820b-Release_020421_105509_DFU_Eden_Bootloader-0.2.5-1d70012-Release.zip",
    "testing bootloader upload"
  ),
  createData(
    "0.2.4",
    "Feb 5, 2021",
    false,
    "/Continuous_master_Eden-0.2.4-284820b-Release_020421_105509_DFU_Eden_Bootloader-0.2.5-1d70012-Release.zip",
    "latest version latest latest"
  ),
];

export default function NeedsPage() {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h5">Needs</Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Version</TableCell>
              <TableCell align="right">Created at</TableCell>
              <TableCell align="right">Should Force update</TableCell>
              <TableCell align="right">Update text</TableCell>
              <TableCell align="right">Url</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.version}>
                <TableCell component="th" scope="row">
                  {row.version}
                </TableCell>
                <TableCell align="right">{row.createdAt}</TableCell>
                <TableCell align="right">
                  {row.shouldForceUpdate ? "yes" : "no"}
                </TableCell>
                <TableCell align="right">{row.updateText}</TableCell>
                <TableCell align="right">{trunc(row.url, 25)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
