import { initialAppState } from "../redux/ducks/app";
import { initAuthState } from "../redux/ducks/auth";
import { DefaultRootState } from "../types";
import { initUsersState } from "../users/store/initUsersState";

const initialStoreState: DefaultRootState = {
  app: initialAppState,
  auth: initAuthState,
  db_users: initUsersState,
};
export default initialStoreState;
