import { AppBar, CssBaseline, Toolbar, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import React from "react";
import { Route, RouteProps } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);

interface DefaultLayutProps extends RouteProps {
  element: JSX.Element[] | JSX.Element;
  path: string;
}

const DefaultLayout = ({ element, ...rest }: DefaultLayutProps) => {
  const classes = useStyles();
  return (
    <SnackbarProvider maxSnack={1}>
      <Route {...rest}>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" noWrap>
                Embr Labs
              </Typography>
            </Toolbar>
          </AppBar>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            {element}
          </main>
        </div>
      </Route>
    </SnackbarProvider>
  );
};

export default DefaultLayout;
