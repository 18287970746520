import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import { Observable, Observer } from "rxjs";
import { User } from "../types";
import UsersRepository from "./UsersRepository";

class AuthApplicationService {
  private static async genUsername(): Promise<string> {
    const result = await firebase
      .functions()
      .httpsCallable("generateUserName")();
    return result.data.username;
  }
  static onAuthStateChange(): Observable<User | null> {
    const authState$ = new Observable((observer: Observer<User | null>) => {
      firebase.auth().onAuthStateChanged(
        async (user: any) => {
          if (user) {
            observer.next(user.toJSON());
          } else {
            observer.next(null);
          }
        },
        (error: firebase.auth.Error) => observer.error(error),
        () => observer.complete()
      );
    });

    return authState$;
  }

  static async userSignedInHandler(
    userId: string,
    user: User
  ): Promise<User | null> {
    const userRecord = await UsersRepository.getUserById(userId);

    if (userRecord === null) {
      let actualUser = {
        ...user,
        username: await AuthApplicationService.genUsername(),
      };

      await UsersRepository.createUser(userId, actualUser);
      return actualUser;
    }
    return userRecord;
  }
}

export default AuthApplicationService;
