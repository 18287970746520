import React, { useEffect, useState } from "react";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { Redirect, Switch } from "react-router-dom";
import { Snackbar } from "@material-ui/core";

import DefaultLayout from "../layouts/DefaultLayout";
import HomePage from "../home/pages/HomePage";
function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PublicRoutes = ({
  redirect,
  error,
}: {
  redirect: string;
  error: Error;
}) => {
  const [doRedirect, setDoRedirect] = useState(false);
  const [prevRedirect, setPrevRedirect] = useState("");
  const [errorOpen, setErrorOpen] = React.useState(true);
  useEffect(() => {
    if (redirect !== prevRedirect) {
      setDoRedirect(true);
      setPrevRedirect(redirect);
    }
  }, [redirect]);

  useEffect(() => {
    if (error) {
      setErrorOpen(true);
    } else {
      setErrorOpen(false);
    }
  }, [error]);
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  return (
    <>
      {doRedirect ? <Redirect to={redirect} /> : null}
      {error ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={errorOpen}
          autoHideDuration={6000}
          onClose={() => false}
          key={"top+center"}
        >
          <Alert
            onClose={handleClose}
            severity="error"
          >{`${error.message}`}</Alert>
        </Snackbar>
      ) : null}
      <Switch>
        <DefaultLayout exact path="/" element={<HomePage />} />
      </Switch>
    </>
  );
};

export default PublicRoutes;
