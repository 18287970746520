import React from "react";
import {
  createStyles,
  Fade,
  LinearProgress,
  makeStyles,
  Theme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingBar: {
      position: "absolute",
      width: "100%",
      zIndex: 9999999999999,
      top: 0,
      left: 0,
    },
  })
);

interface LoadingBarProps {
  loading: boolean;
}

const LoadingBar = ({ loading }: LoadingBarProps) => {
  const classes = useStyles();
  return (
    <Fade in={loading}>
      <LinearProgress className={classes.loadingBar} />
    </Fade>
  );
};

export default LoadingBar;
