import { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Divider,
  Grid,
  Button,
} from "@material-ui/core";
import {
  Edit as EditIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchModeClasses } from "../../redux/ducks/modes";
import { Mode, Modes } from "../../types/Modes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {},
    table: {
      minWidth: 650,
    },
    tableHeader: {
      margin: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
    tableHeaderButtonGroup: {
      marginLeft: theme.spacing(5),
    },
  })
);

function createData(
  version: string,
  createdAt: string,
  shouldForceUpdate: boolean,
  url: string,
  updateText: string
) {
  return { version, createdAt, shouldForceUpdate, url, updateText };
}
function trunc(val: string, n: number) {
  return val.substr(0, n - 1) + (val.length > n ? "&hellip;" : "");
}

export default function ModeClassesPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modes, setModes] = useState<Modes>({});
  const auth = useSelector((state: any) => state.auth);
  const dbModes = useSelector((state: any) => state.db_modes.byId);

  const accessToken = auth.stsTokenManager.accessToken;
  useEffect(() => {
    dispatch(fetchModeClasses.request({ uid: auth.uid, accessToken }));
  }, []);
  useEffect(() => {
    setModes(dbModes);
  }, [dbModes]);
  return (
    <Grid container spacing={3} direction="column">
      <Grid container className={classes.tableHeader}>
        <Grid item>
          <Typography variant="h5">Mode Classes</Typography>
        </Grid>
        <Grid item className={classes.tableHeaderButtonGroup}>
          <Button
            color="secondary"
            className={classes.button}
            size="small"
            startIcon={<AddIcon />}
          >
            ADD
          </Button>
          <Button
            color="secondary"
            disabled
            className={classes.button}
            size="small"
            startIcon={<RemoveIcon />}
          >
            REMOVE
          </Button>
        </Grid>
      </Grid>

      <Divider />
      <Grid item>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(modes).map((key: string) => {
                const row: Mode = modes[key];
                const { id, name, description } = row;
                return (
                  <TableRow key={id}>
                    <TableCell component="th" scope="row">
                      {" "}
                      {name}{" "}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {" "}
                      {description}{" "}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <EditIcon />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
