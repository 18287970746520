import { Reducer } from "redux";
import produce from "immer";
import "firebase/auth";
import { from, Observable, of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { Action, createAction, getType, isActionOf } from "typesafe-actions";
import { Mode, ModesState } from "../../types/Modes";

export const fetchModeClasses = {
  request: createAction("modes/fetchModeClasses/request")<{
    uid: string;
    accessToken: string;
  }>(),
  success: createAction("modes/fetchModeClasses/success")<{ modes: any }>(),
  error: createAction("modes/fetchModeClasse/error")<Error>(),
};

const fetchModeClassesLogic = async (payload: {
  uid: string;
  accessToken: string;
}) => {
  const { uid, accessToken } = payload;
  const host = "https://eden-gateway-qa.com";

  const body = JSON.stringify({
    firebaseId: uid,
    sortOrder: "ASC",
  });

  const headers = {
    Accept: "*/*",
    Authorization: accessToken,
    "Content-Type": "application/json",
    "Accept-Encoding": "gzip, deflate, br",
  };

  const response = await fetch(`${host}/modes.get.all`, {
    method: "POST",
    headers,
    body,
  });

  const { data } = await response.json();

  return data;
};

export const fetchModesEpic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(isActionOf(fetchModeClasses.request)),
    switchMap((action) =>
      from(fetchModeClassesLogic(action.payload)).pipe(
        map((modes: any) => {
          return fetchModeClasses.success({ modes });
        }),
        catchError((error) => of(fetchModeClasses.error(error)))
      )
    )
  );
const initModesState: ModesState = {
  byId: {},
};

export const ModesReducer: Reducer<ModesState, any> = produce(
  (draft, action) => {
    switch (action.type) {
      case getType(fetchModeClasses.success):
        const modes: { [id: string]: Mode } = {};

        action.payload.modes.forEach((mode: Mode) => {
          modes[mode.id] = { ...mode };
        });

        draft.byId = modes;
        break;
    }
  },
  initModesState
);
