import { Reducer } from "redux";
import produce from "immer";
import { getType } from "typesafe-actions";
import { AppState } from "../../types/App";
import { signInWithGoogle } from "./auth";

export const initialAppState: AppState = {
  loading: false,
  redirect: "",
  error: undefined,
};

const AppReducer: Reducer<AppState, any> = produce((draft, action) => {
  if (action.type.includes("request")) {
    draft.loading = true;
    draft.error = undefined;
  } else if (action.type.includes("success")) {
    draft.loading = false;
    draft.error = undefined;
  } else if (action.type.includes("error")) {
    draft.loading = false;
    draft.error = action.payload;
  }
  switch (action.type) {
    case getType(signInWithGoogle.error):
      draft.loading = false;
      draft.error = {
        name: "Unauthorized",
        message: action.payload.message,
      };
      draft.redirect = "/";
      break;
  }
}, initialAppState);

export default AppReducer;
