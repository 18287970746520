import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { from, Observable, of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { AuthState, User } from "../../types";
import { Action, createAction, getType, isActionOf } from "typesafe-actions";
// import AuthApplicationService from "../../domain/AuthApplicationService";

export const initAuthState: AuthState = {
  loading: false,
  role: "guest",
};

export const signInWithGoogle = {
  requestWithPopUp: createAction("auth/signinWithGoogle/requestWithPopup")(),
  request: createAction("auth/signinWithGoogle/request")(),
  success: createAction("auth/signinWithGoogle/success")<{ user: User }>(),
  error: createAction("auth/signinWithGoogle/error")<Error>(),
};

export const onAuthStateChange = {
  withUser: createAction("auth/signinWithGoogle/error")<any>(),
  withoutUser: createAction("auth/signinWithGoogle/error")(),
};
export const signOut = {
  request: createAction("auth/signOut/request")(),
  success: createAction("auth/signOut/success")(),
  error: createAction("auth/signOut/error")<Error>(),
};

const AuthReducer = (
  state: AuthState | undefined = initAuthState,
  action: any
): AuthState => {
  switch (action.type) {
    case getType(signOut.request):
    case getType(signInWithGoogle.request):
    case getType(signInWithGoogle.requestWithPopUp):
      return {
        ...state,
        loading: true,
      };
    case getType(signInWithGoogle.success):
      const user = action.payload.user;
      return {
        ...user,
        loading: false,
        role: "user",
      };
    case getType(signOut.success):
      return { role: "guest", loading: false };
    default:
      return state;
  }
};

export default AuthReducer;

const signOutLogic = async () => {
  await firebase.auth().signOut();
};

const signInWithGooglePopUpLogic = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  await firebase.auth().signInWithPopup(provider);
  const currentUser = firebase.auth().currentUser;
  // console.log(currentUser);
  // let actualUserOnDb = null;
  // if (currentUser) {
  //   actualUserOnDb = await AuthApplicationService.userSignedInHandler(
  //     currentUser?.uid,
  //     currentUser.toJSON() as User
  //   );
  // }
};

export const signInWithGoogleEpic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(isActionOf(signInWithGoogle.requestWithPopUp)),
    switchMap(() =>
      from(signInWithGooglePopUpLogic()).pipe(
        map((user: any) => {
          return signInWithGoogle.success({ user });
        }),
        catchError((error) => of(signInWithGoogle.error(error)))
      )
    )
  );

export const signOutEpic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(isActionOf(signOut.request)),
    switchMap(() => from(signOutLogic()).pipe(map(() => signOut.success())))
  );
