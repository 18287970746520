import { Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SignedInLayout from "../layouts/SignedInLayout";
import BootloadersPage from "../bootloaders/pages/BootloadersPage";
import FirmwaresPage from "../firmwares/pages/FirmwaresPage";
import ModeClassesPage from "../modes/pages/ModeClassesPage";
import NeedsPage from "../needs/pages/NeedsPage";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UserRoutes = ({
  redirect,
  error,
}: {
  redirect: string;
  error: Error;
}) => {
  const [doRedirect, setDoRedirect] = useState(false);
  const [prevRedirect, setPrevRedirect] = useState("");
  const [errorOpen, setErrorOpen] = React.useState(true);

  useEffect(() => {
    if (redirect !== prevRedirect) {
      setDoRedirect(true);
      setPrevRedirect(redirect);
    }
  }, [redirect]);

  useEffect(() => {
    if (error) {
      setErrorOpen(true);
    } else {
      setErrorOpen(false);
    }
  }, [error]);
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  return (
    <>
      {doRedirect ? <Redirect to={redirect} /> : null}
      {error ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={errorOpen}
          autoHideDuration={6000}
          onClose={() => false}
          key={"top+center"}
        >
          <Alert
            onClose={handleClose}
            severity="error"
          >{`${error.name}: ${error.message}`}</Alert>
        </Snackbar>
      ) : null}

      <Switch>
        <Route exact path="/">
          <Redirect to="firmwares" />
        </Route>
        <Route exact path="/bootloaders">
          <SignedInLayout>
            <BootloadersPage />
          </SignedInLayout>
        </Route>
        <Route exact path="/firmwares">
          <SignedInLayout>
            <FirmwaresPage />
          </SignedInLayout>
        </Route>
        <Route exact path="/mode-classes">
          <SignedInLayout>
            <ModeClassesPage />
          </SignedInLayout>
        </Route>
        <Route exact path="/needs">
          <SignedInLayout>
            <NeedsPage />
          </SignedInLayout>
        </Route>
      </Switch>
    </>
  );
};

export default UserRoutes;
