/* eslint-disable global-require */
/* eslint-disable no-undef */
import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore, StoreEnhancer } from "redux";
import { createEpicMiddleware } from "redux-observable";
import rootEpic from "./epics";
import initialStoreState from "./initialStoreState";
import rootReducer from "./reducers";

export const history = createBrowserHistory();

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const epicMiddleware = createEpicMiddleware();

const rootEnhacer: StoreEnhancer = composeEnhancer(
  applyMiddleware(epicMiddleware)
);

const store = createStore(rootReducer(), initialStoreState, rootEnhacer);
epicMiddleware.run(rootEpic);

export const dispatch = store.dispatch;
export default store;
