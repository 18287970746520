import produce from "immer";
import { combineReducers } from "redux";
import { Reducer } from "typesafe-actions";
import appReducer from "../redux/ducks/app";
import AuthReducer from "../redux/ducks/auth";
import { AppReducers, UsersState } from "../types";
import { initUsersState } from "../users/store/initUsersState";
import { ModesReducer as db_modes } from "../redux/ducks/modes";

const db_users: Reducer<UsersState, any> = produce((draft, action) => {},
initUsersState);

export default () => {
  const reducers: AppReducers = {
    app: appReducer,
    auth: AuthReducer,
    db_users,
    db_modes,
  };
  return combineReducers(reducers);
};
