import {
  createMuiTheme,
  ThemeProvider,
  useMediaQuery,
} from "@material-ui/core";
import React from "react";
import { Provider, useSelector } from "react-redux";
import { BrowserRouter, Switch } from "react-router-dom";
import { signInWithGoogle, signOut } from "./redux/ducks/auth";
import store from "./app/configureStore";
import PublicRoutes from "./app/PublicRoutes";
import UserRoutes from "./app/UserRoutes";
import LoadingBar from "./common/components/LoadingBar";
import AuthApplicationService from "./domain/AuthApplicationService";
import "./app/firebase";
import { AuthState, DefaultRootState } from "./types";
// const base_url = "http://localhost:8080";
const base_url = "https://eden-gateway-qa.com";
AuthApplicationService.onAuthStateChange().subscribe({
  next: (user) => {
    if (user) {
      const token = user.stsTokenManager.accessToken;
      const headers = {
        Authorization: token,
        accept: "*/*",
        "Content-Type": "application/json",
      };

      fetch(`${base_url}/roles.user.get`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          firebaseId: user.uid,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          const roleName = data.data[0]?.name;
          if (roleName === "admin") {
            store.dispatch(signInWithGoogle.success({ user }));
          } else {
            let message = "";
            if (roleName === undefined) {
              message = `You haven’t been authorized to access this internal web portal. If you believe you should have access, please contact webmaster@embrlabs.com.`;
            } else {
              message = `User role ${roleName} is not authorized`;
            }
            store.dispatch(signInWithGoogle.error(new Error(message)));
          }
        })
        .catch((error) => {
          debugger;
          store.dispatch(signInWithGoogle.error(error));
        });
    } else {
      store.dispatch(signOut.request());
    }
  },
  error: (error) => console.log(`Error: ${error.message}`),
  complete: () => console.log("completed"),
});

const AppController = ({ children }: any) => {
  const auth = useSelector((store: DefaultRootState) => store.auth);
  //TODO: we can pass the app state as one object instead of individual sub keys
  const loading = useSelector((store: DefaultRootState) => store.app.loading);
  const redirect = useSelector((store: DefaultRootState) => store.app.redirect);
  const error = useSelector((store: DefaultRootState) => store.app.error);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          primary: { main: "#222148" },
          secondary: { main: "#4942FF" },
          type: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  );
  return (
    <ThemeProvider theme={theme}>
      {children({ auth, loading, redirect, error })}
    </ThemeProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <AppController>
            {({
              auth,
              loading,
              redirect,
              error,
            }: {
              auth: AuthState;
              loading: boolean;
              redirect: string;
              error: Error;
            }) => {
              if (error && error.name == "Unauthorized") {
                return <PublicRoutes redirect={redirect} error={error} />;
              } else if (auth.loading) {
                return (
                  <>
                    <LoadingBar loading={true} />
                    <PublicRoutes redirect={redirect} error={error} />
                  </>
                );
              } else if (auth.uid) {
                return (
                  <>
                    <LoadingBar loading={loading} />
                    <UserRoutes redirect={redirect} error={error} />
                  </>
                );
              } else {
                return <PublicRoutes redirect={redirect} error={error} />;
              }
            }}
          </AppController>
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
